import setFullbanner from './home/fullbanner';
import setCarousel from '../components/carousel';
import { updatePriceBlock } from '../components/utilities.js';
//addImports

const Home = {
  init: function () {
    setFullbanner()
    setCarousel();
    updatePriceBlock()
    //calls
  },
};

window.addEventListener('DOMContentLoaded', () => {
  Home.init()
})
